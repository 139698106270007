<template>
  <div
    ref="ModalCreateGiayBienNhan"
    class="modal fade"
    id="ModalCreateGiayBienNhan"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalCreateGiayBienNhanLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between p-3 mt-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100 uppercase">
            <span>Giấy biên nhận</span>
          </div>
          <button
            @click="closeModal"
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body" v-loading="isLoading">
          <div class="row">
            <div class="col-md-6 flex h-10 items-center">
              <div class>
                <label class="fs-14 fw-700">Họ và tên:</label>
                <label for class="ml-1 font-semibold">{{ person.name }}</label>
              </div>
            </div>
            <div class="col-md-3 flex h-10 items-center">
              <label class="fs-14 fw-700 mr-1">Giới tính:</label>
              <label for>{{ person.gender === 1 ? "Nam" : "Nữ" }}</label>
            </div>
            <div class="col-md-3 flex h-10 items-center">
              <div class>
                <label class="fs-14 fw-700 mr-1">Ngày sinh:</label>
                <label for>
                  {{
                  person.birthday &&
                  moment(person.birthday).format("DD/MM/YYYY")
                  }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 flex h-10 items-center">
              <div class>
                <label class="fs-14 fw-700">Địa chỉ:</label>
                <label for class="ml-1">{{ person.address }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 flex h-10 items-center">
              <label class="fs-14 fw-700" style="min-width: 85px">Nghiên cứu:</label>
              <label
                for
                class="ml-1"
              >{{ detail && detail.reimbursement && detail.reimbursement.research_name || reimbursementInfo && reimbursementInfo.research_name }}</label>
            </div>
            <div class="col-md-4 flex h-10 items-center">
              <label class="fs-14 fw-700 mr-1" style="min-width: 50px">Chu kỳ:</label>
              <label
                for
                class="ml-1"
              >{{ detail && detail.reimbursement && detail.reimbursement.research_period || reimbursementInfo && reimbursementInfo.research_period }}</label>
            </div>
            <!-- <div class="col-md-4 flex h-10 items-center">
              <label class="fs-14 fw-700 mr-1" style="min-width: 100px">Mã NC của BN:</label>
              <b-form-input v-model="form.research_code" placeholder="Nhập mã NC của BN"></b-form-input>
            </div>-->
          </div>
          <div class="row">
            <div class="col-md-12 flex h-10 items-center">
              <div class>
                <label class="fs-14 fw-700">Thanh toán/hỗ trợ chi phí:</label>
                <label for class="ml-1">{{ checkExpense }}</label>
              </div>
            </div>
          </div>
          <!-- <div class="row mb-3" v-if="form.is_travel === 2">
            <div class="col-md-12 flex h-10">
              <b-form-input v-model="form.other_reason_notes" placeholder="Nhập..."></b-form-input>
            </div>
          </div>-->
          <div class="row">
            <div class="col-md-12 flex h-10">
              <div class="flex w-100 items-center">
                <label class="fs-14 fw-700 w-16">Số tiền:</label>
                <b-form-input
                  v-model="form.transaction_amount"
                  placeholder="Nhập số tiền"
                  id="input-price"
                ></b-form-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 flex h-10">
              <div class="flex w-100 items-center">
                <label class="fs-14 fw-700">Bằng chữ:</label>
                <label for class="ml-1">{{stringPrice}}</label>
              </div>
            </div>
          </div>
          <div>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-700">Ngày tạo giấy biên nhận:</label>
                  <el-date-picker v-model="form.date" type="datetime" placeholder="Chọn ngày giờ"></el-date-picker>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-700">Người giao tiền:</label>
                  <el-select
                    v-model="form.handover_id"
                    placeholder="Chọn Người giao tiền"
                    class="w-100"
                    clearable
                  >
                    <el-option
                      v-for="item in listDoctors"
                      :key="item.doctor.id"
                      :label="item.doctor.user.name"
                      :value="item.doctor.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-700">Mã bệnh nhân:</label>
                  <b-form-input v-model="form.receiver_pid" placeholder="Nhập mã bệnh nhân"></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <el-button @click="onSubmit" type="primary" :loading="isLoading">Lưu phiếu</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import { mapState } from 'vuex'
import appUtils from '@/utils/appUtils'

export default {
  name: 'ModalCreateGiayBienNhan',
  components: { CkeditorNormal },
  props: ['type', 'listDoctors', 'reimbursementInfo'],
  data () {
    const defaultForm = {
      reimbursement_id: this.reimbursementInfo?.id || '',
      transaction_amount: this.reimbursementInfo?.reimbursement_amount || '',
      receiver_id: this.person?.id || '',
      receiver_pid: this.person?.pid || '',
      handover_id: '',
      date: ''
    }

    return {
      form: {
        ...defaultForm
      },
      defaultForm,
      isLoading: false,
      detail: {},
      typeForm: ''
    }
  },
  watch: {
    'form.transaction_amount' (value) {
      this.form.transaction_amount = appUtils.numberFormat(value)
    }
  },
  async mounted () {
    const inputElement = document.getElementById('input-price')

    inputElement.addEventListener('keypress', function (event) {
      // eslint-disable-next-line no-useless-escape
      const regex = /[a-zA-Z ?:!;'"{}\[\]|/\\+=<>#$%&^*()@]/g
      const inputValue = event.target.value + event.key

      if (regex.test(inputValue)) {
        event.preventDefault()
      }
    })
  },
  computed: {
    ...mapState({
      emrData: state => state.emrData,
      person: state => {
        let newAddress = ''
        const data = state.person
        if (data?.address) {
          newAddress += data.address
        }

        if (data?.address_detail) {
          newAddress += data.address_detail
        }

        if (data?.address_wards_name) {
          newAddress = newAddress + ', ' + data.address_wards_name
        }
        if (data?.address_district_name) {
          newAddress = newAddress + ', ' + data.address_district_name
        }
        if (data?.address_province_name) {
          newAddress = newAddress + ', ' + data.address_province_name
        }

        return {
          ...state.person,
          address: newAddress
        }
      }
    }),
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain || {}
    }),
    stringPrice () {
      let string = ''
      if (this.form.transaction_amount) {
        string = appUtils.numberToWords(
          +this.form.transaction_amount?.toString().replaceAll(',', '')
        )
      }
      let convertString = string[0]?.toUpperCase() + string.slice(1)
      return convertString === 'undefined' ? '' : convertString
    },
    checkExpense () {
      if (this.reimbursementInfo?.id) {
        if (this.reimbursementInfo?.is_travel_reimbursement === 1) { return 'Đi lại' } else return this.reimbursementInfo?.other_reimbursement_notes
      }
      if (this.detail?.id) {
        if (this.detail?.reimbursement?.is_travel_reimbursement === 1) { return 'Đi lại' } else return this.detail?.reimbursement?.other_reimbursement_notes
      }
    }
  },
  methods: {
    closeModal () {
      if (this.type === 'create') {
        this.form = { ...this.defaultForm }
      }
    },
    async openModal (show, reimbursementInfo) {
      console.log('open')
      window.$('#ModalCreateGiayBienNhan').modal(show ? 'show' : 'hide')
      if (reimbursementInfo && reimbursementInfo.id) {
        this.typeForm = reimbursementInfo.type
      }
      if (
        this.type === 'update' ||
        (reimbursementInfo && reimbursementInfo.type === 'update')
      ) {
        await this.getDetailGiayBienNhan(
          this.$route.query.vId || reimbursementInfo.id
        )
        this.form = {
          transaction_amount: this.detail?.transaction_amount || '',
          reimbursement_id: this.detail?.reimbursement_id,
          receiver_id: this.person?.id,
          receiver_pid: this.detail?.receiver_pid,
          handover_id: this.detail?.handover_id || '',
          date: window.moment
            .utc(this.detail?.date * 1000)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')
        }
      } else {
        this.form = {
          transaction_amount: reimbursementInfo?.reimbursement_amount || '',
          reimbursement_id: reimbursementInfo?.id,
          receiver_id: this.person?.id,
          receiver_pid: this.person?.pid,
          handover_id: '',
          date: new Date()
        }
      }

      let self = this
      self.$nextTick(function () {
        // if (this.type !== 'update') this.form = this.defaultForm
        window
          .$('#ModalCreateGiayBienNhan')
          .on('shown.bs.modal', function (e) {})

        window.$('#ModalCreateGiayBienNhan').on('hidden.bs.modal', function (e) {
          console.log('Close modal')
        })
      })
    },
    async onSubmit () {
      try {
        this.isLoading = true
        let params = {
          reimbursement_id: +this.reimbursementInfo?.id || 0,
          // person_id: +this.person?.id,
          receiver_id: this.person?.id
        }
        if (this.form.date) {
          params.date = window.moment(this.form.date).format('YYYY-MM-DD HH:mm')
        }
        if (this.form.receiver_pid) params.receiver_pid = this.form.receiver_pid
        if (this.form.transaction_amount) {
          params.transaction_amount =
            +this.form.transaction_amount.replaceAll(',', '') ||
            this.reimbursementInfo?.reimbursement_amount.replaceAll(',', '')
        }
        if (this.form.handover_id) params.handover_id = this.form.handover_id
        let result = null
        if (this.type === 'update' || this.typeForm === 'update') {
          params.reimbursement_confirm_id = this.detail?.id
          result = await this.$rf
            .getRequest('HIDRequest')
            .updateEmrReimbursementConfirm(params)
          if (result) {
            this.$toast.open({
              message: 'Cập nhật Giấy biên nhận thành công',
              type: 'success'
            })
            this.$emit('update')
          }
        } else {
          result = await this.$rf
            .getRequest('HIDRequest')
            .createEmrReimbursementConfirm(params)

          if (result) {
            this.$toast.open({
              message: 'Tạo mới Giấy biên nhận thành công',
              type: 'success'
            })
          }
          this.$emit('success')
        }
        this.resetForm()
      } catch (error) {
        console.log('🚀 ~ onSubmit ~ error:', error)
        this.$toast.open({
          message: 'Có lỗi xảy ra, vui lòng thử lại',
          type: 'error'
        })
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    numberFormat (number) {
      return appUtils.numberFormat(number, 'VNĐ')
    },
    async getDetailGiayBienNhan (id) {
      try {
        this.isLoading = true
        const res = await this.$rf
          .getRequest('HIDRequest')
          .getDetailEmrReimbursementConfirm(id)
        this.detail = res?.data || {}
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    resetForm () {
      this.form = { ...this.defaultForm }
      this.isLoading = false
    }
  }
}
</script>
    <style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: '\02026';
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
.modal-body {
  max-height: 700px;
  overflow: auto;
  font-weight: 400;
}
.form--input {
  line-height: 44px;
}
.add-location {
  color: #20419b;
  display: flex;
  justify-content: end;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.modal-dialog {
  @media screen and (min-width: 1200px) {
    width: 80%;
    max-width: unset;
  }
}
.icon {
  width: 40px;
  object-fit: contain;
  transition: all ease-in-out 0.2s;
  &:hover {
    scale: 1.1;
  }
}
</style>
    