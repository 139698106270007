<template>
  <div class="flex justify-center">
    <div class="text-gray-400 text-3xl mr-3 cursor-pointer fw-600 mt-32">
      Đang cập nhật
    </div>
  </div>
</template>
      
    <script>
export default {
  name: 'PhieuGayMeDetail',
  components: {}

}
    </script>
    