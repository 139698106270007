<template>
  <div>
    <div ref="ModalCreateGiayDeNghiThanhToan" class="modal fade" id="ModalCreateGiayDeNghiThanhToan" tabindex="-1" role="dialog" aria-labelledby="ModalCreateGiayDeNghiThanhToanLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3 mt-3">
            <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
              <span>GIẤY ĐỀ NGHỊ THANH TOÁN</span>
            </div>
            <button @click="closeModal" type="button" id="closeModal" class="close bg-white border-0" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 flex h-10">
                <div class>
                  <label class="fs-14 fw-700 w-44">Họ và tên:</label>
                  <label for>{{ person.name }}</label>
                </div>
              </div>
              <div class="col-md-3 flex h-10">
                <label class="fs-14 fw-700 mr-1">Giới tính:</label>
                <label for>{{ person.gender === 1 ? "Nam" : "Nữ" }}</label>
              </div>
              <div class="col-md-3 flex h-10">
                <div class>
                  <label class="fs-14 fw-700 mr-1">Ngày sinh:</label>
                  <label for>
                    {{ person.birthday && moment(person.birthday).format("DD/MM/YYYY") }}
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 flex h-10">
                <div class>
                  <label class="fs-14 fw-700 w-44">Địa chỉ:</label>
                  <label for>{{ person.address }}</label>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12 flex h-10">
                <div class="flex w-100 items-center">
                  <label class="fs-14 fw-700 w-44">Người đề nghị:</label>
                  <b-form-input v-model="form.person_request" placeholder="Nhập tên người đề nghị"></b-form-input>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4 flex h-10 items-center">
                <label class="fs-14 fw-700" style="min-width: 85px">Nghiên cứu:</label>
                <b-form-input v-model="form.research_name" placeholder="Nhập..."></b-form-input>
              </div>
              <div class="col-md-4 flex h-10 items-center">
                <label class="fs-14 fw-700 mr-1" style="min-width: 50px">Chu kỳ:</label>
                <b-form-input v-model="form.research_period" placeholder="Nhập..."></b-form-input>
              </div>
              <div class="col-md-4 flex h-10 items-center">
                <label class="fs-14 fw-700 mr-1" style="min-width: 100px">Mã NC của BN:</label>
                <b-form-input v-model="form.research_code" placeholder="Nhập mã NC của BN"></b-form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 flex" :class="form.is_travel === 1 ? 'h-10' : 'mb-2'">
                <div class>
                  <label class="fs-14 fw-700 w-44">Thanh toán/hỗ trợ chi phí:</label>
                  <el-radio-group v-model="form.is_travel">
                    <el-radio :label="1">Đi lại</el-radio>
                    <el-radio :label="2">Khác</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div class="row mb-3" v-if="form.is_travel === 2">
              <div class="col-md-12 flex h-10">
                <b-form-input v-model="form.other_reason_notes" placeholder="Nhập..."></b-form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 flex h-10">
                <div class="flex w-100 items-center">
                  <label class="fs-14 fw-700 w-44">Số tiền:</label>
                  <b-form-input v-model="form.reimbursement_amount" placeholder="Nhập số tiền" id="input-price"></b-form-input>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 flex h-10">
                <div class="flex w-100 items-center">
                  <label class="fs-14 fw-700 w-44">Bằng chữ:</label>
                  <b-form-input v-model="stringPrice" disabled></b-form-input>
                </div>
              </div>
            </div>
            <div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="fs-14 fw-700">Điều phối viên hành chính:</label>
                    <el-select v-model="form.admin_id" placeholder="Chọn Điều phối viên hành chính" class="w-100" clearable>
                      <el-option v-for="item in listDoctors" :key="item.doctor.id" :label="item.doctor.user.name" :value="item.doctor.id"></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="fs-14 fw-700">Điều phối viên quy trình:</label>
                    <el-select v-model="form.procedure_id" placeholder="Chọn Điều phối viên quy trình" class="w-100" clearable>
                      <el-option v-for="item in listDoctors" :key="item.doctor.id" :label="item.doctor.user.name" :value="item.doctor.id"></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label class="fs-14 fw-700">Ngày tạo:</label>
                    <el-date-picker v-model="form.date" type="datetime" placeholder="Chọn ngày giờ"></el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <el-button @click="onSubmit" type="primary" :loading="isLoading">Lưu phiếu</el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showPrint">
      <div v-show="false" ref="documentPrint">
        <ContentFormGiayDeNghiThanhToan :detail="detail"></ContentFormGiayDeNghiThanhToan>
      </div>
    </div>
  </div>
</template>
  <script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import { mapState } from 'vuex'
import appUtils from '../../../../utils/appUtils'
import { ENVELOPE_DOCUMENT_TYPE, ENVELOPE_NAME_TYPE, SIGNATORY_ROLE } from '../../../../utils/constants'
import { mixinSignPdf } from '../../../../utils/mixinSignPdf'
import ContentFormGiayDeNghiThanhToan from './ContentFormGiayDeNghiThanhToan.vue'

export default {
  name: 'ModalCreateGiayDeNghiThanhToan',
  components: { CkeditorNormal, ContentFormGiayDeNghiThanhToan },
  mixins: [mixinSignPdf],
  props: ['type', 'listDoctors'],
  data () {
    const defaultForm = {
      person_request: '',
      research_name: '',
      research_period: '',
      research_code: '',
      is_travel: '',
      other_reason_notes: '',
      reimbursement_amount: '',
      admin_id: '',
      procedure_id: '',
      date: ''
    }

    return {
      form: {
        ...defaultForm
      },
      defaultForm,
      isLoading: false,
      detail: {},
      showPrint: false
    }
  },
  watch: {
    'form.reimbursement_amount' (value) {
      this.form.reimbursement_amount = appUtils.numberFormat(value)
    }
  },
  mounted () {
    const inputElement = document.getElementById('input-price')

    inputElement.addEventListener('keypress', function (event) {
      // eslint-disable-next-line no-useless-escape
      const regex = /[a-zA-Z ?:!;'"{}\[\]|/\\+=<>#$%&^*()@]/g
      const inputValue = event.target.value + event.key

      if (regex.test(inputValue)) {
        event.preventDefault()
      }
    })
  },
  computed: {
    ...mapState({
      emrData: state => state.emrData,
      person: state => {
        let newAddress = ''
        const data = state.person
        if (data?.address) {
          newAddress += data.address
        }

        if (data?.address_detail) {
          newAddress += data.address_detail
        }

        if (data?.address_wards_name) {
          newAddress = newAddress + ', ' + data.address_wards_name
        }
        if (data?.address_district_name) {
          newAddress = newAddress + ', ' + data.address_district_name
        }
        if (data?.address_province_name) {
          newAddress = newAddress + ', ' + data.address_province_name
        }

        return {
          ...state.person,
          address: newAddress
        }
      }
    }),
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain || {},
      emrData: state => state.emrData
    }),
    stringPrice () {
      let string = appUtils.numberToWords(+this.form.reimbursement_amount?.toString().replaceAll(',', ''))
      let convertString = string[0]?.toUpperCase() + string.slice(1)
      return convertString
    },
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrData)
    }
  },
  methods: {
    closeModal () {
      this.showPrint = false

      if (this.type === 'create') {
        this.form = { ...this.defaultForm }
      }
    },
    async openModal (show) {
      this.showPrint = false

      window.$('#ModalCreateGiayDeNghiThanhToan').modal(show ? 'show' : 'hide')
      let self = this
      if (this.type === 'update') {
        await this.getDetailGiayDeNghi()
        this.form = {
          person_request: this.detail?.person_request || this.person?.name || '',
          research_name: this.detail?.research_name,
          research_period: this.detail?.research_period,
          research_code: this.detail?.research_code,
          is_travel: this.detail?.is_travel_reimbursement,
          other_reason_notes: this.detail?.other_reimbursement_notes,
          reimbursement_amount: this.detail?.reimbursement_amount || '',
          admin_id: this.detail?.admin_coordinator_id || '',
          procedure_id: this.detail?.procedure_coordinator_id || '',
          date: window.moment
            .utc(this.detail?.date * 1000)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')
        }
      } else {
        this.form = { ...this.defaultForm, person_request: this.person?.name, date: new Date() }
      }
      self.$nextTick(function () {
        // if (this.type !== 'update') this.form = this.defaultForm
        window.$('#ModalCreateGiayDeNghiThanhToan').on('shown.bs.modal', function (e) {})

        window.$('#ModalCreateGiayDeNghiThanhToan').on('hidden.bs.modal', function (e) {
          console.log('Close modal')
        })
      })
    },
    async getDetailGiayDeNghi (id) {
      try {
        this.isLoading = true
        const res = await this.$rf.getRequest('HIDRequest').getDetailEmrReimbursementRequest(id || this.$route.query?.vId)
        this.detail = res?.data || {}
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async onSubmit () {
      if (this.isLoading) return
      try {
        this.isLoading = true
        let params = {
          medical_record_id: +this.$route.params?.id || 0,
          person_id: +this.person?.id
        }
        if (this.form.person_request) {
          params.person_request = this.form.person_request
        }
        if (this.form.research_name) {
          params.research_name = this.form.research_name
        }
        if (this.form.research_period) {
          params.research_period = this.form.research_period
        }
        if (this.form.research_code) {
          params.research_code = this.form.research_code
        }
        if (this.form.reimbursement_amount) {
          params.reimbursement_amount = +this.form.reimbursement_amount.replaceAll(',', '')
        }
        if (this.form.admin_id) params.admin_id = this.form.admin_id
        if (this.form.date) {
          params.date = window.moment(this.form.date).format('YYYY-MM-DD HH:mm')
        }
        if (this.form.procedure_id) {
          params.procedure_id = this.form.procedure_id
        }
        if (this.form.is_travel === 1) params.is_travel = this.form.is_travel
        if (this.form.is_travel === 2) {
          params.is_travel = this.form.is_travel
          params.other_reason_notes = this.form.other_reason_notes
        }
        let result = null
        if (this.type === 'update') {
          params.reimbursement_id = this.detail?.id
          result = await this.$rf.getRequest('HIDRequest').updateEmrReimbursementRequest(params)
        } else {
          result = await this.$rf.getRequest('HIDRequest').createEmrReimbursementRequest(params)
        }

        if (result) {
          await this.getDetailGiayDeNghi(result?.data?.id)
          // this.showPrint = true

          this.$nextTick(async () => {
            // const url = await this.onPrintNe()
            // this.isLoading = true
            // await this.handleProcessSignEnvelope(url)

            this.$emit('success')
            this.resetForm()
            this.isLoading = false

            if (this.type === 'update') {
              this.$toast.open({
                message: 'Cập nhật Giấy đề nghị thanh toán thành công',
                type: 'success'
              })
              this.$emit('update')
            } else {
              this.$toast.open({
                message: 'Tạo mới Giấy đề nghị thanh toán thành công',
                type: 'success'
              })
            }
          })
        }
      } catch (error) {
        console.log('🚀 ~ onSubmit ~ error:', error)
        this.$toast.open({
          message: 'Có lỗi xảy ra, vui lòng thử lại',
          type: 'error'
        })
        this.isLoading = false
      }
    },
    resetForm () {
      this.form = { ...this.defaultForm }
      this.isLoading = false
    },
    async handleProcessSignEnvelope (documentUrl) {
      if (!documentUrl) return

      let responseData
      const procedureSelected = await this.getSignProcedures(ENVELOPE_DOCUMENT_TYPE.REIMBURSEMENT_REQUEST_FORM)
      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map(item => {
          const user = this.getUserByRole(item?.user_role)

          return {
            ...item,
            user,
            user_id: user?.id,
            node_signatory_id: item?.id,
            signatory_name: user?.name,
            signatory_email: user?.email,
            signatory_role: SIGNATORY_ROLE.signer,
            signatory_order: item?.sign_order,
            role_name: item?.role_name,
            is_required: item?.is_required,
            expired_date: item?.expired_date || undefined
          }
        })

        return arr.concat(signs)
      }, [])

      const signEnvelope = await this.handleGetEnvelopeByDocumentID(this.detail.id, ENVELOPE_DOCUMENT_TYPE.REIMBURSEMENT_REQUEST_FORM)

      if (signEnvelope?.id) {
        responseData = await this.handleUpdateSignEnvelope(signEnvelope.id, signatories, documentUrl, ENVELOPE_DOCUMENT_TYPE.REIMBURSEMENT_REQUEST_FORM, this.detail.id, this.envelopeName)

        return responseData
      } else {
        responseData = await this.handleCreateEnvelope(signatories, documentUrl, procedureSelected?.id, ENVELOPE_DOCUMENT_TYPE.REIMBURSEMENT_REQUEST_FORM, this.detail.id, this.envelopeName)

        return responseData
      }
    },
    getUserByRole (role) {
      let user
      switch (role) {
        case 'admin_coordinator':
          const admin_coordinator = this.detail?.admin_coordinator_id
          if (!admin_coordinator) {
            // eslint-disable-next-line standard/no-callback-literal
            this.loading = false
          } else {
            user = this.detail?.admin_coordinator_doctor?.user || {}
          }
          break
        case 'procedure_coordinator':
          const procedure_coordinator = this.detail?.procedure_coordinator_id
          if (!procedure_coordinator) {
            // eslint-disable-next-line standard/no-callback-literal
            this.loading = false
          } else {
            user = this.detail?.procedure_coordinator_doctor?.user || {}
          }
          break
        case 'patient':
          const patient = this.detail?.person_id
          if (!patient) this.loading = false
          else {
            user =
              (this.detail?.person && {
                ...this.detail?.person,
                name: this.detail?.person_request || this.detail?.person?.name
              }) ||
              {}
          }
          break

        default:
          break
      }
      return user
    },
    async onPrintNe () {
      const self = this
      const appendStyle = true
      const bodyHTML = self.$refs.documentPrint.innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML, appendStyle })
      const base64Data = appUtils.base64.decode(tempHTML)
      const url = await this.savePdfToS3(base64Data, false)
      return url
    },
    async savePdfToS3 (base64, isOpen = true) {
      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName: 'Giaydenghithanhtoan',
          path: 'consultations',
          type: 'test',
          type_id: 'test',
          uuid: 'test'
        }

        const res = await this.$rf.getRequest('DoctorRequest').printDocument(payload)

        if (res?.data && isOpen) {
          this.loading = false
          window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
        return res.data?.documentLink
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    }
  }
}
</script>
  <style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: "\02026";
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
.modal-body {
  max-height: 700px;
  overflow: auto;
  font-weight: 400;
}
.form--input {
  line-height: 44px;
}
.add-location {
  color: #20419b;
  display: flex;
  justify-content: end;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.modal-dialog {
  @media screen and (min-width: 1200px) {
    width: 80%;
    max-width: unset;
  }
}
.icon {
  width: 40px;
  object-fit: contain;
  transition: all ease-in-out 0.2s;
  &:hover {
    scale: 1.1;
  }
}
</style>
