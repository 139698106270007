<template>
  <div
    ref="ModalCreateGiayChungNhan"
    class="modal fade"
    id="ModalCreateGiayChungNhan"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalCreateGiayChungNhanLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between p-3 mt-3">
          <div class="fs-24 fw-700 txt-grey-900 text-center w-100">
            <span>GIẤY XÁC NHẬN NGHỈ VIỆC HƯỞNG BẢO HIỂM XÃ HỘI</span>
          </div>
          <button
            @click="closeModal"
            type="button"
            id="closeModal"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span class="uppercase font-bold mt-4 fs-18">I. Thông tin người bệnh</span>
          <div class="row mt-2">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Họ và tên:</label>
                <label for>{{ person.name }}</label>
              </div>
            </div>
            <div class="col-md-3 flex h-10">
              <label class="fs-14 fw-700 mr-1">Giới tính:</label>
              <label for>{{ person.gender === 1 ? "Nam" : "Nữ" }}</label>
            </div>
            <div class="col-md-3 flex h-10">
              <div class>
                <label class="fs-14 fw-700 mr-1">Ngày sinh:</label>
                <label for>
                  {{
                  person.birthday &&
                  moment(person.birthday).format("DD/MM/YYYY")
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Địa chỉ:</label>
              <label for>{{ person.address }}</label>
            </div>
            <div class="col-md-6 flex h-10">
              <label class="fs-14 fw-700 w-44">Nơi làm việc:</label>
              <label for>{{ person.work_at || ''}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Mã số BHXH:</label>
                <label for></label>
              </div>
            </div>
            <div class="col-md-6 flex h-10">
              <div class>
                <label class="fs-14 fw-700 w-44">Mã thẻ:</label>
                <label for>{{ person.insurance_number || "" }}</label>
              </div>
            </div>
          </div>

          <span class="uppercase font-bold mt-4 fs-18">II. Chẩn đoán và phương pháp điều trị</span>
          <div class="row mt-2">
            <div class="col-md-12 flex h-10">
              <div class>
                <label class="fs-14 fw-700">Chẩn đoán:</label>
                <label class="ml-2">
                  {{
                  medicalRecordMain && medicalRecordMain.treat_dept_main_name
                  }}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 flex h-10">
              <div class>
                <label class="fs-14 fw-700">Số ngày nghỉ:</label>
                <label
                  class="ml-2"
                  v-if="medicalRecordMain && medicalRecordMain.reception_at && medicalRecordMain.discharge_at"
                >{{ numOfDays }} (ngày)</label>
              </div>
            </div>
            <div class="col-md-4 flex h-10">
              <div class>
                <label class="fs-14 fw-700">Từ ngày:</label>
                <label
                  class="ml-2"
                  v-if="medicalRecordMain && medicalRecordMain.reception_at"
                >{{ getDate(medicalRecordMain.reception_at) }}</label>
              </div>
            </div>
            <div class="col-md-4 flex h-10">
              <div class>
                <label class="fs-14 fw-700">Đến ngày:</label>
                <label
                  class="ml-2"
                  v-if="medicalRecordMain && medicalRecordMain.discharge_at"
                >{{ getDate(medicalRecordMain.discharge_at) }}</label>
              </div>
            </div>
          </div>
          <el-checkbox v-model="is_baby" class="flex items-center">Bệnh nhân là trẻ em dưới 7 tuổi</el-checkbox>
          <div v-show="is_baby" class="mt-3">
            <span class="uppercase font-bold fs-18">III. Thông tin cha, mẹ</span>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-700">Họ và tên cha:</label>
                  <b-form-input v-model="form.father_name" placeholder="Họ và tên cha..."></b-form-input>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label class="fs-14 fw-700">Họ và tên mẹ:</label>
                  <b-form-input v-model="form.mother_name" placeholder="Họ và tên mẹ..."></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-700">Thủ trưởng đơn vị:</label>
                  <el-select
                    v-model="form.head_id"
                    placeholder="Chọn thủ trưởng đơn vị"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in listDoctors"
                      :key="item.doctor.id"
                      :label="item.doctor.user.name"
                      :value="item.doctor.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="fs-14 fw-700">Người hành nghề KB, CB:</label>
                  <el-select
                    v-model="form.examiner_id"
                    placeholder="Chọn người hành nghề KB, CB"
                    class="w-100"
                  >
                    <el-option
                      v-for="item in listDoctors"
                      :key="item.doctor.id"
                      :label="item.doctor.user.name"
                      :value="item.doctor.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="onSubmit"
            type="button"
            class="btn bg-pri text-white"
            :busy="isLoading"
          >Lưu phiếu</button>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import CkeditorNormal from '@/components/Editors/CkeditorNormal.vue'
import { mapState } from 'vuex'
export default {
  name: 'ModalCreateGiayChungNhan',
  components: { CkeditorNormal },
  props: ['type', 'listDoctors'],
  data () {
    const defaultForm = {
      father_name: '',
      mother_name: '',
      head_id: '',
      examiner_id: '',
      medical_record_id: +this.$route.params?.id
    }
    return {
      form: {
        ...defaultForm
      },
      defaultForm,
      is_baby: false,
      // listDoctors: [],
      isLoading: false
    }
  },
  watch: {},
  mounted () {
    if (this.type === 'update') this.getDetailForm()
  },
  computed: {
    computedProperty () {
      return [
        this.form.cr_id,
        this.form.re_examination_time,
        this.showTimeSlot
      ].join()
    },
    ...mapState({
      emrData: state => state.emrData,
      person: state => {
        let newAddress = ''
        const data = state.person
        if (data?.address) {
          newAddress += data.address
        }

        if (data?.address_detail) {
          newAddress += data.address_detail
        }

        if (data?.address_wards_name) {
          newAddress = newAddress + ', ' + data.address_wards_name
        }
        if (data?.address_district_name) {
          newAddress = newAddress + ', ' + data.address_district_name
        }
        if (data?.address_province_name) {
          newAddress = newAddress + ', ' + data.address_province_name
        }

        return {
          ...state.person,
          address: newAddress
        }
      }
    }),
    ...mapState({
      medicalRecordMain: state => state.medicalRecordMain || {}
    }),
    numOfDays () {
      const startDate = new Date(this.medicalRecordMain.reception_at).getTime()
      const endDate = new Date(this.medicalRecordMain.discharge_at).getTime()
      return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24))
    }
  },
  methods: {
    closeModal () {
      if (this.type === 'create') {
        this.form = { ...this.defaultForm }
        this.is_baby = false
      }
    },
    openModal (show) {
      window.$('#ModalCreateGiayChungNhan').modal(show ? 'show' : 'hide')
      this.getClinicDoctors()
      let self = this
      // if (this.type === 'update') self.getDetailForm()
      self.$nextTick(function () {
        this.form = this.defaultForm
        this.is_baby = false
        window.$('#ModalCreateGiayChungNhan').on('shown.bs.modal', function (e) {
          // self.form.before_diagnose =
          //   self.medicalRecordMain?.treat_dept_main_name || ''
        })

        window
          .$('#ModalCreateGiayChungNhan')
          .on('hidden.bs.modal', function (e) {
            console.log('Close modal')
          })
      })
    },
    getDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    async onSubmit () {
      try {
        this.isLoading = false
        let params = {}
        if (this.is_baby && this.form.father_name) {
          params.father_name = this.form.father_name
        } else params.father_name = ''
        if (this.is_baby && this.form.mother_name) {
          params.mother_name = this.form.mother_name
        } else params.mother_name = ''
        if (this.form.examiner_id) params.examiner_id = this.form.examiner_id
        if (this.form.head_id) params.head_id = this.form.head_id
        params.medical_record_id = this.form.medical_record_id
        if (this.type === 'update') {
          const res = await this.$rf
            .getRequest('DoctorRequest')
            .updateLeaveCertificates(this.$route.query?.vId, params)
          if (res) this.$emit('update')
        } else {
          const res = await this.$rf
            .getRequest('DoctorRequest')
            .createLeaveCertificates(params)
          if (res) {
            this.$emit('create')
            this.form = { ...this.defaultForm }
          }
        }
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    },
    async getDetailForm () {
      try {
        this.isLoading = true
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailLeaveCertificates(this.$route?.query?.vId)
        this.form = res?.data || {}
        if (this.form.father_name || this.form.mother_name) this.is_baby = true
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
  <style lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reverse-ellipsis {
  /* Your move. */
  text-overflow: clip;
  position: relative;
  background-color: #fff;
}
.reverse-ellipsis:before {
  content: '\02026';
  position: absolute;
  z-index: 1;
  left: -2em;
  background-color: inherit;
  padding-left: 1em;
  margin-left: 0.5em;
}

.reverse-ellipsis span {
  min-width: 100%;
  position: relative;
  display: inline-block;
  float: right;
  overflow: visible;
  background-color: inherit;
  text-indent: 0.5em;
}

.reverse-ellipsis span:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: inherit;
  z-index: 200;
  left: -0.5em;
}
.modal-body {
  max-height: 700px;
  overflow: auto;
  font-weight: 400;
}
.form--input {
  line-height: 44px;
}
.add-location {
  color: #20419b;
  display: flex;
  justify-content: end;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.icon {
  width: 40px;
  object-fit: contain;
  transition: all ease-in-out 0.5s;
  &:hover {
    scale: 1.2;
  }
}
</style>
  