<template>
  <div class="flex justify-center">
    <div class="text-gray-400 text-3xl mr-3 cursor-pointer fw-600 w-100">
      <div v-if="!showDetail">
        <div class="mb-0 text-blue-800 text-base flex justify-end">
          <div class="flex items-center">
            <span @click="handleOpenModal" class="fs-14 fw-700 text-grey-900 cursor-pointer">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
                <path
                  d="M8.99984 0.666748C7.35166 0.666748 5.7405 1.15549 4.37009 2.07117C2.99968 2.98685 1.93158 4.28834 1.30084 5.81105C0.670115 7.33377 0.505087 9.00933 0.82663 10.6258C1.14817 12.2423 1.94185 13.7272 3.10728 14.8926C4.27272 16.0581 5.75758 16.8517 7.37409 17.1733C8.9906 17.4948 10.6662 17.3298 12.1889 16.6991C13.7116 16.0683 15.0131 15.0002 15.9288 13.6298C16.8444 12.2594 17.3332 10.6483 17.3332 9.00008C17.3332 7.90573 17.1176 6.8221 16.6988 5.81105C16.28 4.80001 15.6662 3.88135 14.8924 3.10752C14.1186 2.3337 13.1999 1.71987 12.1889 1.30109C11.1778 0.882296 10.0942 0.666748 8.99984 0.666748ZM8.99984 15.6667C7.6813 15.6667 6.39237 15.2758 5.29604 14.5432C4.19971 13.8107 3.34523 12.7695 2.84064 11.5513C2.33606 10.3331 2.20404 8.99269 2.46127 7.69948C2.71851 6.40627 3.35345 5.21839 4.2858 4.28604C5.21815 3.35369 6.40603 2.71875 7.69924 2.46151C8.99245 2.20428 10.3329 2.3363 11.5511 2.84088C12.7692 3.34547 13.8104 4.19995 14.543 5.29628C15.2755 6.39261 15.6665 7.68154 15.6665 9.00008C15.6665 10.7682 14.9641 12.4639 13.7139 13.7141C12.4636 14.9644 10.768 15.6667 8.99984 15.6667ZM12.3332 8.16675H9.83317V5.66675C9.83317 5.44573 9.74538 5.23377 9.5891 5.07749C9.43282 4.92121 9.22085 4.83342 8.99984 4.83342C8.77883 4.83342 8.56687 4.92121 8.41059 5.07749C8.25431 5.23377 8.16651 5.44573 8.16651 5.66675V8.16675H5.66651C5.44549 8.16675 5.23353 8.25455 5.07725 8.41083C4.92097 8.56711 4.83317 8.77907 4.83317 9.00008C4.83317 9.2211 4.92097 9.43306 5.07725 9.58934C5.23353 9.74562 5.44549 9.83342 5.66651 9.83342H8.16651V12.3334C8.16651 12.5544 8.25431 12.7664 8.41059 12.9227C8.56687 13.079 8.77883 13.1667 8.99984 13.1667C9.22085 13.1667 9.43282 13.079 9.5891 12.9227C9.74538 12.7664 9.83317 12.5544 9.83317 12.3334V9.83342H12.3332C12.5542 9.83342 12.7662 9.74562 12.9224 9.58934C13.0787 9.43306 13.1665 9.2211 13.1665 9.00008C13.1665 8.77907 13.0787 8.56711 12.9224 8.41083C12.7662 8.25455 12.5542 8.16675 12.3332 8.16675Z"
                  fill="#03267A"
                />
              </svg>
              Thêm Giấy đề nghị
            </span>
          </div>
        </div>

        <div v-loading="isLoading && !update.show" class="handleLoading">
          <div class="table-grid header">
            <div class="title"></div>
            <div class="title">Tên</div>
            <div class="title">Chu kỳ</div>
            <div class="title">Người giao tiền</div>
            <div class="title text-right">Số tiền</div>
          </div>
          <div v-for="(item, index) in paymentDemands" :key="index">
            <el-collapse v-model="requestActive">
              <el-collapse-item :name="index + 1" class="collase-header-date">
                <template slot="title">
                  <div>
                    <i class="el-icon-date mx-1"></i>
                    <span>{{ item.date }}</span>
                  </div>
                </template>
                <div v-for="(request, requestIndex) in item.data" :key="requestIndex">
                  <el-collapse v-model="confirmActive">
                    <el-collapse-item class="collase-header-request" :name="`${index + 1}-${requestIndex + 1}`">
                      <template slot="title">
                        <div class="table-grid">
                          <div class="table-content" style="margin-left: 16px">
                            <i class="el-icon-time"></i>
                            {{ request.reimbursement && formatTime(request.reimbursement, true) }}
                          </div>
                          <div class="table-content">
                            {{ request.reimbursement && request.reimbursement.research_name }}
                          </div>
                          <div class="table-content">
                            {{ request.reimbursement && request.reimbursement.research_period }}
                          </div>
                          <div class="table-content"></div>
                          <div class="table-content text-right">
                            {{ request.reimbursement && numberFormat(request.reimbursement.reimbursement_amount, "VNĐ") }}
                          </div>
                          <div class="table-content flex justify-end items-center w-100">
                            <div class="inline-block" @click.stop="handleOpenModalCreateGiayBienNhan(request, item.date), (isConflictClick = true)">
                              <el-button size="mini">
                                <i class="el-icon-plus mr-1"></i>
                                Giấy biên nhận
                              </el-button>
                            </div>
                            <div class="view ml-3" style="margin-right: 10px" @click.stop="goToViewDetail(request)">
                              <svg style="width: 24px; height: 24px" class="cursor-pointer" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="#20409B" />
                                <mask id="mask0_35008_774" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="18" height="18">
                                  <rect x="6.92859" y="6.92871" width="16.1429" height="16.1429" fill="#D9D9D9" stroke="#20409B" />
                                </mask>
                                <g mask="url(#mask0_35008_774)">
                                  <path
                                    d="M15 17.8701C15.8962 17.8701 16.6583 17.5563 17.2857 16.9289C17.913 16.3015 18.2268 15.5394 18.2268 14.6433C18.2268 13.7471 17.913 12.985 17.2857 12.3577C16.6583 11.7303 15.8962 11.4165 15 11.4165C14.1039 11.4165 13.3418 11.7303 12.7144 12.3577C12.087 12.985 11.7732 13.7471 11.7732 14.6433C11.7732 15.5394 12.087 16.3015 12.7144 16.9289C13.3418 17.5563 14.1039 17.8701 15 17.8701ZM15 16.5593C14.4676 16.5593 14.0154 16.3731 13.6428 16.0005C13.2702 15.6279 13.084 15.1757 13.084 14.6433C13.084 14.1108 13.2702 13.6587 13.6428 13.286C14.0154 12.9134 14.4676 12.7272 15 12.7272C15.5325 12.7272 15.9846 12.9134 16.3573 13.286C16.7299 13.6587 16.9161 14.1108 16.9161 14.6433C16.9161 15.1757 16.7299 15.6279 16.3573 16.0005C15.9846 16.3731 15.5325 16.5593 15 16.5593ZM15 19.9879C13.2644 19.9879 11.6835 19.5035 10.257 18.5347C8.83193 17.5668 7.7984 16.2698 7.15632 14.6433C7.7984 13.0168 8.83193 11.7197 10.257 10.7518C11.6835 9.78301 13.2644 9.29863 15 9.29863C16.7356 9.29863 18.3165 9.78301 19.743 10.7518C21.1681 11.7197 22.2016 13.0168 22.8437 14.6433C22.2016 16.2698 21.1681 17.5668 19.743 18.5347C18.3165 19.5035 16.7356 19.9879 15 19.9879Z"
                                    fill="#20409B"
                                    stroke="#20409B"
                                    stroke-width="0.025"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div v-if="!!request.emr_reimbursement_confirms">
                        <div class="table-grid confirm" v-for="(confirm, confirmIndex) in request.emr_reimbursement_confirms" :key="confirmIndex" @click="goToViewDetail(confirm)">
                          <div class="content-confirm" style="margin-left: 32px">
                            {{ confirm.date && formatDate(confirm, true) }}
                          </div>
                          <div class="content-confirm">Giấy biên nhận ({{ formatTime(confirm, true) }})</div>
                          <div class="content-confirm"></div>
                          <div class="content-confirm">
                            {{ confirm.handover_doctor && confirm.handover_doctor.user && confirm.handover_doctor.user.name }}
                          </div>
                          <div class="content-confirm text-right">
                            {{ numberFormat(confirm.transaction_amount, "VNĐ") }}
                          </div>
                          <div class="content-confirm text-right" style="margin-right: 10px">
                            <svg style="width: 24px; height: 24px" class="cursor-pointer" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" stroke="#20409B" />
                              <mask id="mask0_35008_774" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="18" height="18">
                                <rect x="6.92859" y="6.92871" width="16.1429" height="16.1429" fill="#D9D9D9" stroke="#20409B" />
                              </mask>
                              <g mask="url(#mask0_35008_774)">
                                <path
                                  d="M15 17.8701C15.8962 17.8701 16.6583 17.5563 17.2857 16.9289C17.913 16.3015 18.2268 15.5394 18.2268 14.6433C18.2268 13.7471 17.913 12.985 17.2857 12.3577C16.6583 11.7303 15.8962 11.4165 15 11.4165C14.1039 11.4165 13.3418 11.7303 12.7144 12.3577C12.087 12.985 11.7732 13.7471 11.7732 14.6433C11.7732 15.5394 12.087 16.3015 12.7144 16.9289C13.3418 17.5563 14.1039 17.8701 15 17.8701ZM15 16.5593C14.4676 16.5593 14.0154 16.3731 13.6428 16.0005C13.2702 15.6279 13.084 15.1757 13.084 14.6433C13.084 14.1108 13.2702 13.6587 13.6428 13.286C14.0154 12.9134 14.4676 12.7272 15 12.7272C15.5325 12.7272 15.9846 12.9134 16.3573 13.286C16.7299 13.6587 16.9161 14.1108 16.9161 14.6433C16.9161 15.1757 16.7299 15.6279 16.3573 16.0005C15.9846 16.3731 15.5325 16.5593 15 16.5593ZM15 19.9879C13.2644 19.9879 11.6835 19.5035 10.257 18.5347C8.83193 17.5668 7.7984 16.2698 7.15632 14.6433C7.7984 13.0168 8.83193 11.7197 10.257 10.7518C11.6835 9.78301 13.2644 9.29863 15 9.29863C16.7356 9.29863 18.3165 9.78301 19.743 10.7518C21.1681 11.7197 22.2016 13.0168 22.8437 14.6433C22.2016 16.2698 21.1681 17.5668 19.743 18.5347C18.3165 19.5035 16.7356 19.9879 15 19.9879Z"
                                  fill="#20409B"
                                  stroke="#20409B"
                                  stroke-width="0.025"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div v-else class="flex items-center justify-center w-100 font-normal fs-14" style="height: 40px">Chưa có biên nhận nào.</div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
      <div v-else>
        <Detail @reset="fetchData" @delete="fetchData" :triggerPatientSign="triggerPatientSign" />
      </div>
    </div>
    <ModalCreateGiayDeNghiThanhToan ref="ModalCreateGiayDeNghiThanhToan" :listDoctors="listDoctors" @success="handleCreateSuccessGiayDeNghi"></ModalCreateGiayDeNghiThanhToan>
    <ModalCreateGiayBienNhan ref="ModalCreateGiayBienNhan" :listDoctors="listDoctors" :reimbursementInfo="reimbursementInfo" @success="handleCreateSuccessGiayBienNhan" @update="handleUpdateSuccess"></ModalCreateGiayBienNhan>

    <ModalSignPDF ref="ListGiayDeNghiModalSignPDF" containerIDProps="ListGiayDeNghiContainerSignPDFID" elementIDProps="ListGiayDeNghiSignPDFID" imageIDProps="ListGiayDeNghiSignPDFImageID" @onClose="fetchData" />
    <PdfViewer ref="ListGiayDeNghiPdfViewer" containerIDProps="ContainerListGiayDeNghiPDFViewerID" elementIDProps="ListGiayDeNghiPDFViewerID" @onClose="fetchData" />
  </div>
</template>

  <script>
import appUtils from '@/utils/appUtils'
import moment from 'moment'
import { ENVELOPE_DOCUMENT_TYPE } from '../../../utils/constants'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import Detail from './Detail.vue'
import ModalCreateGiayDeNghiThanhToan from './components/ModalCreateForm.vue'
import ModalCreateGiayBienNhan from './components/ModalCreateGiayBienNhan.vue'

export default {
  name: 'GiayDeNghiThanhToan',
  components: {
    Detail,
    ModalCreateGiayDeNghiThanhToan,
    ModalCreateGiayBienNhan,
    PdfViewer,
    ModalSignPDF
  },
  mixins: [mixinSignPdf],
  data () {
    return {
      showDetail: false,
      isLoading: false,
      update: {
        form: {
          name: '',
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
          pdf_url: '',
          pdf: null,
          anaesthetic_main: '',
          surgical_main: ''
        },
        show: false
      },
      paymentDemands: [],
      pagination: {
        page_size: 1000,
        page_num: 1,
        total: 0
      },
      listDoctors: [],
      isConflictClick: false,
      reimbursementInfo: {},
      type: '',
      requestActive: [1],
      confirmActive: [],
      triggerPatientSign: 0
    }
  },
  watch: {
    '$route.query.vId' () {
      this.handleOpenDetail()
    }
  },
  mounted () {
    if (this.$route.query.vId) this.showDetail = true
    this.fetchData()
    this.getClinicDoctors()
  },
  methods: {
    async getClinicDoctors () {
      try {
        const params = {
          page: 1,
          limit: 1000
        }
        const res = await this.$rf.getRequest('DoctorRequest').getClinicDoctor(this.$globalClinic?.id, params)
        this.listDoctors = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    handleOpenModal () {
      this.$refs.ModalCreateGiayDeNghiThanhToan.openModal(true)
    },
    handleOpenModalCreateGiayBienNhan (row, date) {
      this.reimbursementInfo = this.paymentDemands
        ?.find(elm => {
          return elm.date === date
        })
        ?.data?.find(item => item.reimbursement?.id === row.reimbursement?.id)?.reimbursement
      this.type = ''
      this.$refs.ModalCreateGiayBienNhan.openModal(true, this.reimbursementInfo)
    },
    handleOpenDetail () {
      if (this.$route.query.vId) {
        this.showDetail = true
      } else {
        this.showDetail = false
        this.fetchData()
      }
    },
    formatDate (row, isUTC) {
      return row.date && appUtils.formatDate(row.date * 1000, isUTC)
    },
    formatTime (row, isUTC) {
      return row.date && window.moment.utc(row.date * 1000).format('HH:mm')
    },
    async fetchData () {
      this.confirmActive = []
      try {
        this.isLoading = true
        const params = {
          page_size: this.pagination.page_size,
          page_num: this.pagination.page_num,
          medical_record_id: this.$route.params?.id,
          order: 'desc',
          sort_by: 'date'
        }
        const res = await this.$rf.getRequest('HIDRequest').getListEmrReimbursementGroupByDate(params)
        this.paymentDemands = res?.data
        for (let i = 0; i < this.paymentDemands[0]?.data?.length; i++) {
          let key = `1-${i + 1}`
          this.confirmActive.push(key)
        }
        this.pagination.total = res?.data?.page?.total
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async goToViewDetail (row) {
      // this.isLoading = true
      if (this.isConflictClick) {
        this.isConflictClick = false
        return
      }

      const envelopeInfo = await this.openModalSignPDF(row)

      if (envelopeInfo) return

      if (!row.id) {
        this.$router
          .push({
            query: {
              tab: this.$route.query.tab,
              vId: row?.reimbursement?.id,
              type: 'view',
              type_form: 'de_nghi'
            }
          })
          .catch(_ => {})
      } else {
        this.$router
          .push({
            query: {
              tab: this.$route.query.tab,
              vId: row?.id,
              type: 'view',
              type_form: 'bien_nhan'
            }
          })
          .catch(_ => {})
      }
    },
    handleChangeCurrentPage (page) {
      this.pagination.page_num = page
      this.fetchData()
    },
    handleCreateSuccessGiayDeNghi () {
      window.$('#ModalCreateGiayDeNghiThanhToan').modal('hide')
      this.fetchData()
    },
    handleCreateSuccessGiayBienNhan () {
      window.$('#ModalCreateGiayBienNhan').modal('hide')
      this.fetchData()
    },
    handleUpdateSuccess () {
      window.$('#ModalCreateGiayBienNhan').modal('hide')
      this.type = ''
      this.fetchData()
    },
    numberFormat (number, currency) {
      return appUtils.numberFormat(number, currency)
    },
    async openModalSignPDF (docInfo, isPatientSign = false) {
      this.loading = true
      const documentType = docInfo.id ? ENVELOPE_DOCUMENT_TYPE.REIMBURSEMENT_CONFIRM_FORM : ENVELOPE_DOCUMENT_TYPE.REIMBURSEMENT_REQUEST_FORM

      const viewRef = 'ListGiayDeNghiPdfViewer'
      const signRef = 'ListGiayDeNghiModalSignPDF'

      const envelopeInfo = await this.handleCheckSignEnvelope(documentType, docInfo.id ? docInfo?.id : docInfo?.reimbursement?.id, viewRef, signRef, isPatientSign)

      if (!envelopeInfo && isPatientSign) {
        this.medicalOrderId = docInfo?.id
        this.$nextTick(() => {
          this.triggerPatientSign++
        })
      } else {
        this.loading = false
      }

      return envelopeInfo
    },
    loadData (tree, treeNode, resolve) {
      resolve(this.paymentDemands?.find(elm => elm.reimbursement_id === tree.reimbursement_id)?.emr_reimbursement_confirms)
    },
    tableRowClassName ({ row, index }) {
      if (!row.id) {
        return 'text-black text-sm font-normal bg-f7f7f7'
      } else {
        return 'text-black text-sm font-normal'
      }
    },
    handleUpdate (row) {
      this.type = 'update'
      this.$refs.ModalCreateGiayBienNhan.openModal(true, {
        ...row,
        type: this.type
      })
    },
    async handleDelete (row) {
      try {
        this.loading = true
        this.$confirm(this.$t(`Bạn chắc chắn muốn xoá giấy biên nhận này với số tiền ${this.numberFormat(row.transaction_amount, 'VNĐ')} không ?`)).then(async () => {
          const res = await this.$rf.getRequest('HIDRequest').deleteEmrReimbursementConfirm(row.id)
          if (res) {
            this.$toast.open({
              message: 'Xoá giấy biên nhận thành công',
              type: 'success'
            })
            this.fetchData()
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handlePrint () {
      //
    }
  }
}
</script>
  <style lang="scss" scoped>
::v-deep.el-table {
  .el-table__body {
    .bg-f7f7f7 {
      background: #f7f7f7;
    }
    .w-150 {
      width: 150px;
    }
  }
}
.table-grid {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
  .title {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }
  .table-content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
  .content-confirm {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 12px 0;
  }
}
.confirm {
  border-top: 1px solid #ebeef5;
  width: calc(100% - 21px);
}
.header {
  width: calc(100% - 21px);
}
::v-deep.el-collapse {
  .el-collapse-item__wrap {
    border-bottom: none;
  }
  .el-collapse-item .el-collapse-item__header {
    border-bottom: 1px solid #fff;
  }
  .collase-header-date {
    .el-collapse-item__header {
      background: #ededed;
    }
  }
  .collase-header-request {
    .el-collapse-item__header {
      background: #f7f7f7;
    }
  }
}
.view {
  color: #20419b;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
