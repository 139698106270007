<template>
  <div
    id="giayChungNhan"
    class="mx-auto overflow-hidden hodo-print"
    :style="`width: 100% !important`"
  >
    <div class="mt-2 font-normal pl-4">
      <div class="block fs-14">
        <div class="col-4">
          <div class="flex" style="max-width: 250px">
            <div class="text-left">
              <div class>
                <div class="mb-0 fs-17">
                  {{
                  $globalClinic && $globalClinic.organization
                  ? $globalClinic.organization.name
                  : ""
                  }}
                </div>
              </div>
              <div class>
                <div class="mb-0 fs-17">Số:......................</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6" style="vertical-align: bottom">
          <div class="flex justify-center">
            <div class="font fs-17 mr-4">Số seri:...............</div>
            <div class="font fs-17 font-semibold">Mẫu số:...............</div>
          </div>
        </div>
        <div class="col-2">
          <div class="text-right ml-2" style="margin-right: 36px">
            <div class="mb-0 fs-17">Liên số {{ formNumber }}</div>
          </div>
        </div>
      </div>
      <div class="block mt-2">
        <div
          class="font font-bold flex flex-col justify-center items-center uppercase fs-20 text-center"
        >Giấy chứng nhận nghỉ việc hưởng bảo hiểm xã hội</div>
        <div
          class="font flex flex-col justify-center items-center fs-18 mt-1"
        >(chỉ áp dụng cho điều trị ngoại trú)</div>
      </div>
      <div :class="formNumber === 1 ? 'info-border' : 'info'">
        <div class="block mt-3 mb-2">
          <div class="w-full inline-block">
            <div>
              <div class="flex">
                <div class="font font-bold fs-18">I. Thông tin người bệnh</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block mb-2">
          <div class="w-full inline-block">
            <div class="flex justify-between" style="max-width: 90%">
              <div>
                <label class="mr-1 font fs-18">Họ tên người bệnh:</label>
                <span class="uppercase font font-bold fs-18">
                  {{
                  (info && info.person && info.person.name) || person && person.name || '...........................................'
                  }}
                </span>
              </div>

              <div class="flex">
                <div class="font fs-18">Ngày sinh:</div>
                <div class="mb-0 ml-1 font fs-18">
                  {{ getYear(info && info.person && info.person.birthday) ||
                  getDate( person && person.birthday, 'hm') || '..........'
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div class="flex-1 flex items-center">
            <div class="block mb-2">
              <div class="w-full inline-block">
                <div>
                  <div class="flex">
                    <div class="mb-0 font fs-18">Mã số BHXH:</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="block mb-2">
              <div class="w-full inline-block">
                <div>
                  <div class="flex fs-18">
                    <div
                      class="mb-0 font fs-18"
                      style="min-width: 90px;"
                    >..........................................</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div class="flex-2 flex items-center justify-start" style="width: 100%">
            <div class="block mb-2">
              <div class="w-full inline-block">
                <div class="flex">
                  <div class="mb-0 font fs-18" style="min-width: 65px">Mã thẻ:</div>
                </div>
              </div>
            </div>

            <div class="block mb-2 ml-1" style="width: 97%">
              <div class="w-full inline-block">
                <div>
                  <div class="flex fs-18">
                    <BoxNumber
                      style="width: 80% !important"
                      bhyt="true"
                      :str="info && info.person && info.person.insurance_number || person &&  person.insurance_number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-2">
          <label class="font fs-18">Giới tính:</label>
          <span class="font fs-18">
            {{(info && info.person && info.person.gender === 1) ||
            (person.gender && person.gender === 1)
            ? "Nam"
            : (info && info.person && info.person.gender === 2) ||
            (person.gender && person.gender === 2)
            ? 'Nữ' : '..............'
            }}
          </span>
        </div>
        <div class="block mb-2">
          <div class="w-full inline-block">
            <div>
              <div class="flex">
                <div class="font fs-18">Đơn vị làm việc:</div>
                <div
                  class="mb-0 ml-1 font fs-18"
                >{{(info && info.person && info.person.work_at) || person && person.work_at || '...........................................'}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block mt-3 mb-2">
          <div class="w-full inline-block">
            <div>
              <div class="flex">
                <div class="font font-bold fs-18">II. Chẩn đoán và phương pháp điều trị</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block mb-2">
          <div class="w-full inline-block">
            <div class="overflow-hidden">
              <!-- <div class="whitespace-nowrap float-left mr-1 font fs-18 fontForAll">- Chẩn đoán:</div> -->
              <div
                v-if="medicalRecordMain && medicalRecordMain.treat_dept_main_name"
                class="mb-0 font fs-18 fontForAll font-semibold"
              >{{medicalRecordMain.treat_dept_main_code }} - {{ medicalRecordMain.treat_dept_main_name}}</div>
              <div
                class="mt-1 ml-2"
                v-else
              >....................................................................................................................</div>
            </div>
          </div>
        </div>
        <div class="block mb-2">
          <div class="w-full inline-block">
            <div>
              <div class="flex">
                <div class="font fs-18">Số ngày nghỉ:</div>
                <div
                  class="font fs-18 ml-2"
                  v-if="medicalRecordMain && medicalRecordMain.reception_at && medicalRecordMain.discharge_at"
                >{{ numOfDays }} (ngày)</div>
                <div class="font fs-18 ml-2" v-else>............ (ngày)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block mt-3 mb-2">
          <div class="w-full inline-block">
            <div>
              <div class="flex">
                <div class="font fs-18">Từ ngày:</div>
                <div
                  class="font fs-18 ml-2"
                  v-if="medicalRecordMain && medicalRecordMain.reception_at"
                >{{ getDate(medicalRecordMain.reception_at) }}</div>
                <div class="font fs-18 ml-2" v-else>........../........../..........</div>
                <div class="font fs-18 ml-2">đến hết ngày:</div>
                <div
                  class="font fs-18 ml-2"
                  v-if="medicalRecordMain && medicalRecordMain.discharge_at"
                >{{ getDate(medicalRecordMain.discharge_at) }}</div>
                <div class="font fs-18 ml-2" v-else>........../........../..........</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block mt-3 mb-2">
          <div class="w-full inline-block">
            <div>
              <div class="flex">
                <div
                  class="font font-bold fs-18"
                  style="max-width: 97%"
                >III. Thông tin cha, mẹ (chỉ áp dụng đối với trường hợp người bệnh là trẻ em dưới 7 tuổi)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block mt-3 mb-2">
          <div class="w-full inline-block">
            <div>
              <div class="flex">
                <div class="font fs-18">Họ và tên cha:</div>
                <div
                  class="font fs-18 ml-1"
                  v-if="detailForm && detailForm.father_name"
                >{{detailForm.father_name}}</div>
                <div class="font fs-18" v-else>..................................................</div>
              </div>
            </div>
          </div>
        </div>
        <div class="block mt-3 mb-2">
          <div class="w-full inline-block">
            <div>
              <div class="flex">
                <div class="font fs-18">Họ và tên mẹ:</div>
                <div
                  class="font fs-18 ml-1"
                  v-if="detailForm && detailForm.mother_name"
                >{{detailForm.mother_name}}</div>
                <div class="font fs-18" v-else>..................................................</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block mt-4">
        <div class="col-4 text-center">
          <div class="font-bold uppercase font fs-17">Xác nhận của thủ trưởng đơn vị</div>
          <div class="text-italic font fs-17">(Ký tên, đóng dấu)</div>
          <div class="fs-17 font-bold mt-20 font fs-17">{{ head }}</div>
        </div>
        <div class="col-7 text-center ml-4">
          <div class="text-center">
            <div v-if="detailForm && detailForm.created_at" class="mb-0 ml-1 font fs-18">
              {{getHMDMYVN(detailForm && detailForm.created_at)
              }}
            </div>
            <div
              class="font fs-17"
              v-else
            >.........., ngày .......... tháng .......... năm ..........</div>
          </div>
          <div class="font-bold uppercase font fs-17">Người hành nghề KB, CB</div>
          <div class="text-italic font fs-17">(Ký, họ tên, trừ trường hợp sử dụng chữ ký số)</div>
          <div class="fs-17 font-bold mt-20 font fs-17">{{ examiner }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import BoxNumber from '../../../BoxNumber.vue'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
export default {
  name: 'ContentFormGiayChungNhan',
  mixins: [mixinGetPersonInfo],
  components: { BoxNumber },
  props: [
    'customWidth',
    'info',
    'person',
    'detailForm',
    'medicalRecordMain',
    'formNumber',
    'listDoctors'
  ],
  data () {
    return {
      //
    }
  },
  computed: {
    numOfDays () {
      const startDate = new Date(this.medicalRecordMain.reception_at).getTime()
      const endDate = new Date(this.medicalRecordMain.discharge_at).getTime()
      return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24))
    },
    head () {
      return this.listDoctors?.find(
        elm => elm.doctor.id === this.detailForm.head_id
      )?.doctor?.user?.name
    },
    examiner () {
      return this.listDoctors?.find(
        elm => elm.doctor.id === this.detailForm.examiner_id
      )?.doctor?.user?.name
    }
  },
  methods: {
    formatStringDate (date) {
      const dateTime = moment(date).utc()
      let _date = dateTime.date()
      if (_date.toString().length === 1) _date = `0${_date}`
      let month = dateTime.month() + 1
      if (month.toString().length === 1) month = `0${month}`

      return ` ngày ${_date} tháng ${month} năm ${dateTime.year()}`
    },
    formatStringTime (date) {
      const dateTime = moment(date).utc()
      let stringMinute = ''
      let stringHour = ''
      if (!isNaN(dateTime.minute())) {
        if (dateTime.minute() === 0) {
          stringMinute = '00'
        } else {
          stringMinute = dateTime.minute()
          if (stringMinute.toString().length === 1) {
            stringMinute = `0${stringMinute}`
          }
        }
      } else {
        stringMinute = '......'
      }
      if (!isNaN(dateTime.hour())) {
        if (dateTime.hour() === 0) {
          stringHour = '00'
        } else {
          stringHour = dateTime.hour()
          if (stringHour.toString().length === 1) stringHour = `0${stringHour}`
        }
      } else {
        stringHour = '......'
      }
      return `${stringHour} giờ ${stringMinute} phút`
    },
    formatTime (date) {
      return window.moment(date).format('HH:mm')
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatDateUTC (date) {
      const start_time = new Date(date)
      const dd = start_time
        .getUTCDate()
        .toString()
        .padStart(2, '0') // Get day in UTC format
      const mm = (start_time.getUTCMonth() + 1).toString().padStart(2, '0') // Get month in UTC format (Note: Months are 0-based)
      const yyyy = start_time.getUTCFullYear() // Get year in UTC format

      const formattedDate = `${dd}/${mm}/${yyyy}`
      return formattedDate
    },
    getDate (date, type) {
      if (type === 'hm') return window.moment(date).format('DD/MM/YYYY')
      else return window.moment(date).format('HH:mm DD/MM/YYYY')
    },
    getHMDMYVN (date) {
      const string = this.moment(date).locale(this.$lang)
      if (this.$lang === 'vi') {
        // let hm = dt.clone().format('HH:mm')
        const dddd = string.clone().format('dddd')
        const d = string.clone().format('DD')
        const m = string.clone().format('MM')
        const y = string.clone().format('YYYY')

        const arr = dddd.split(' ')
        // loop through each element of the array and capitalize the first letter.
        for (var i = 0; i < arr?.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }
        // Join all the elements of the array back into a string
        // using a blankspace as a separator
        const ddddCapitalized = arr.join(' ')
        return ddddCapitalized + ', ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    getYear (date) {
      return new Date(date)?.getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .content-none {
    width: 100%;
    border-bottom: 2px dotted;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .styleText {
    word-wrap: break-word;
  }

  body,
  html {
    font-family: 'Times New Roman', Tinos, Times, serif !important;
  }
  .font {
    font-family: 'Times New Roman', Tinos, Times, serif !important;
  }
  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }
  p {
    margin-top: 0;
  }
  .text-italic {
    font-style: italic;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .top-0 {
    top: 0;
  }
  .left-0 {
    left: 0;
  }
  .px-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }
  .float-none {
    float: none;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block !important;
  }
  .flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
  .align-top {
    vertical-align: top;
  }
  .text-center {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-center {
    align-items: center;
  }
  .col-3 {
    width: 25% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .col-6 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .col-12 {
    width: 100% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .w-full {
    width: 100% !important;
  }
  .w-half {
    width: 50% !important;
  }
  .w-one-third {
    width: 33.333% !important;
  }
  .w-two-thirds {
    width: 66.667% !important;
  }
  .font-bold {
    font-weight: 700;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-sm {
    font-size: 0.92rem;
    line-height: 1.25rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-base {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }
  .pl-12 {
    padding-left: 3rem;
  }
  .pl-4 {
    padding-left: 16px !important;
  }
  .pl-16 {
    padding-left: 4rem;
  }
  .pr-12 {
    padding-right: 3rem;
  }
  .pr-16 {
    padding-right: 4rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .ml-6 {
    margin-left: 1.5rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .mt-16 {
    margin-top: 4rem;
  }
  .mt-24 {
    margin-top: 6rem;
  }
  .ml-1 {
    margin-left: 0.25rem;
  }
  .mr-1 {
    margin-right: 0.25rem;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .flex-2 {
    flex: 2;
  }
  .hodo-table figure {
    margin: 0 0 1rem !important;
  }
  .box-item-bhyt {
    // width: 45px;
    // min-width: unset !important;
    @media print {
      width: 65px;
    }
  }
  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }
  .hodo-table table thead th {
    text-transform: uppercase !important;
  }
  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }
  .info-border {
    padding-right: 16px;
    border-right: 1px solid #000;
  }
  .info {
    padding-right: 16px;
  }
}
</style>