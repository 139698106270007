<template>
  <div id="phieuChuyenTuyen" class="mx-auto overflow-hidden hodo-print" :style="`width: 1000px !important`">
    <div class="px-8 mt-2 font-normal">
      <div class="block fs-14">
        <div class="col-3">
          <div class="flex" style="max-width: 250px">
            <div class="text-left">
              <div>
                <div class="mb-0 uppercase font-bold fs-17">
                  {{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.authority_in_charge : "" }}
                </div>
              </div>
              <div class>
                <div class="mb-0 uppercase font-bold fs-17">
                  {{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.name : "" }}
                </div>
              </div>
              <div class>
                <div class="mb-0 fs-17">------</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="font font-bold flex flex-col justify-center items-center uppercase fs-24">Đề nghị thanh toán chi phí hỗ trợ cho bệnh nhân</div>
      </div>
      <div class="block mb-2 mt-3">
        <div class="w-full inline-block">
          <div class="flex justify-between">
            <div class="flex-1">
              <label class="font fs-18">Nghiên cứu:</label>
              <span class="font fs-18 ml-1">{{ (detail && detail.research_name) || "..........................................." }}</span>
            </div>
            <div class="mr-6 flex-1">
              <label class="ml-6 font fs-18">Chu kỳ:</label>
              <span class="font fs-18 ml-1">{{ (detail && detail.research_period) || "..........................................." }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="w-full">
            <div class="flex w-full items-center">
              <div class="font fs-18">Đề nghị thanh toán/hỗ trợ chi phí:</div>
              <div class="h-3 w-3 box-nv mx-0 mt-0 mr-1 ml-2 flex justify-center items-center">
                <span v-if="detail && detail.is_travel_reimbursement === 1" class="fs-11 m-0 p-0 font-bold">X</span>
              </div>
              <span class="ml-1 font fs-18 mr-4">Đi lại</span>
              <div class="h-3 w-3 box-nv mx-0 mt-0 mr-1 ml-2 flex justify-center items-center">
                <span v-if="detail && detail.is_travel_reimbursement === 2" class="fs-11 m-0 p-0 font-bold">X</span>
              </div>
              <span class="ml-1 font fs-18">Khác: {{ (detail && detail.other_reimbursement_notes) || "..............................................." }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="flex justify-between">
            <div class="flex-1">
              <label class="mr-1 font fs-18">Cho bệnh nhân:</label>
              <span class="font fs-18 ml-1">{{ (person && person.name) || "..........................................." }}</span>
            </div>
            <div class="mr-6 flex-1">
              <label class="ml-6 font fs-18">Mã NC của bệnh nhân:</label>
              <span class="font fs-18 ml-1">{{ (detail && detail.research_code) || "..........................................." }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div class="flex justify-between">
            <div class="flex-1">
              <label class="mr-1 font fs-18">CCCD/CMND:</label>
              <span class="font fs-18 ml-1">{{ (person && person.cmnd) || ".................................." }}</span>
            </div>
            <div class="mr-6 flex-1">
              <label class="ml-6 font fs-18">Ngày cấp:</label>
              <span class="font fs-18 ml-1">{{ ".................................." }}</span>
            </div>
            <div class="mr-6 flex-1">
              <label class="ml-6 font fs-18">Nơi cấp:</label>
              <span class="font fs-18 ml-1">{{ ".................................." }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex">
              <div class="mb-0 font fs-18">Địa chỉ:</div>
              <span class="font fs-18 ml-1">{{ (person && person.address) || "..........................................." }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex">
              <div class="font fs-18">Số tiền:</div>
              <span class="font fs-18 ml-1">{{ (detail && numberFormat(detail.reimbursement_amount)) || "..........................................." }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block mb-2">
        <div class="w-full inline-block">
          <div>
            <div class="flex">
              <div class="font fs-18">Bằng chữ:</div>
              <span class="font fs-18 ml-1">{{ stringPrice || "..........................................." }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="block">
        <div class="col-4 text-center">
          <div class="text-italic font fs-17" style="height: 24px"></div>
          <div class="text-italic font fs-17" style="height: 24px"></div>
          <div class="font-bold uppercase font fs-17">Điều phối viên hành chính</div>
          <div class="text-italic font fs-17">(Ký, ghi rõ họ tên)</div>
          <div class="fs-17 font-bold mt-20 font fs-17">
            {{ detail && detail.admin_coordinator_doctor && detail.admin_coordinator_doctor.user && detail.admin_coordinator_doctor.user.name }}
          </div>
        </div>
        <div class="col-4 text-center">
          <div class="text-italic font fs-17" style="height: 24px"></div>
          <div class="text-italic font fs-17" style="height: 24px"></div>
          <div class="font-bold uppercase font fs-17">Điều phối viên quy trình</div>
          <div class="text-italic font fs-17">(Ký, ghi rõ họ tên)</div>
          <div class="fs-17 font-bold mt-20 font fs-17">
            {{ detail && detail.procedure_coordinator_doctor && detail.procedure_coordinator_doctor.user && detail.procedure_coordinator_doctor.user.name }}
          </div>
        </div>
        <div class="col-4 text-center">
          <div class="text-center">
            <div v-if="detail && detail.date" class="font fs-17">
              {{ getHMDMYVN(detail && detail.date) }}
            </div>
            <div class="font fs-17" v-else>Ngày .......... tháng .......... năm ..........</div>
            <div class="font font-bold fs-17">Người đề nghị</div>
          </div>
          <div class="font-bold uppercase font fs-17">Bệnh nhân/ Người nhà BN</div>
          <div class="text-italic font fs-17">(Ký, ghi rõ họ tên)</div>
          <div class="fs-17 font-bold mt-20 font fs-17">
            {{ (detail && detail.person_request) || (person && person.name) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import moment from 'moment'
import appUtils from '@/utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import { mapState } from 'vuex'
import BoxNumber from '../../../BoxNumber.vue'

export default {
  name: 'ContentFormGiayDeNghiThanhToan',
  mixins: [mixinGetPersonInfo],
  components: { BoxNumber },
  props: ['detail'],
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapState({
      person: state => {
        let newAddress = ''
        const data = state.person
        if (data?.address) {
          newAddress += data.address
        }

        if (data?.address_detail) {
          newAddress += data.address_detail
        }

        if (data?.address_wards_name) {
          newAddress = newAddress + ', ' + data.address_wards_name
        }
        if (data?.address_district_name) {
          newAddress = newAddress + ', ' + data.address_district_name
        }
        if (data?.address_province_name) {
          newAddress = newAddress + ', ' + data.address_province_name
        }

        return {
          ...state.person,
          address: newAddress
        }
      }
    }),
    stringPrice () {
      let string = appUtils.numberToWords(this.detail?.reimbursement_amount)
      let convertString = string[0]?.toUpperCase() + string.slice(1)
      return convertString
    }
  },
  methods: {
    numberFormat (number) {
      return number ? appUtils.numberFormat(number, 'VNĐ') : ''
    },
    getHMDMYVN (date) {
      const string = this.moment(date * 1000)
        .utc()
        .locale(this.$lang)
      if (this.$lang === 'vi') {
        // let hm = dt.clone().format('HH:mm')
        const dddd = string.clone().format('dddd')
        const d = string.clone().format('DD')
        const m = string.clone().format('MM')
        const y = string.clone().format('YYYY')
        const arr = dddd.split(' ')
        // loop through each element of the array and capitalize the first letter.
        for (var i = 0; i < arr?.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }
        // Join all the elements of the array back into a string
        // using a blankspace as a separator
        // const ddddCapitalized = arr.join(' ')
        return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .content-none {
    width: 100%;
    border-bottom: 2px dotted;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .styleText {
    word-wrap: break-word;
  }

  body,
  html {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }
  .font {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }
  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }
  p {
    margin-top: 0;
  }
  .text-italic {
    font-style: italic;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .top-0 {
    top: 0;
  }
  .left-0 {
    left: 0;
  }
  .px-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }
  .float-none {
    float: none;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block !important;
  }
  .flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
  .align-top {
    vertical-align: top;
  }
  .text-center {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-center {
    align-items: center;
  }
  .col-3 {
    width: 25% !important;
    display: inline-block !important;
    vertical-align: top !important;
  }
  .col-4 {
    width: 33.333% !important;
    display: inline-block !important;
    vertical-align: top !important;
  }
  .col-6 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top !important;
  }
  .col-12 {
    width: 100% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .w-full {
    width: 100% !important;
  }
  .w-half {
    width: 50% !important;
  }
  .w-one-third {
    width: 33.333% !important;
  }
  .w-two-thirds {
    width: 66.667% !important;
  }
  .font-bold {
    font-weight: 700;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-sm {
    font-size: 0.92rem;
    line-height: 1.25rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-base {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }
  .pl-12 {
    padding-left: 3rem;
  }
  .pl-16 {
    padding-left: 4rem;
  }
  .pr-12 {
    padding-right: 3rem;
  }
  .pr-16 {
    padding-right: 4rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .ml-6 {
    margin-left: 1.5rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .mt-16 {
    margin-top: 4rem;
  }
  .mt-24 {
    margin-top: 6rem;
  }
  .ml-1 {
    margin-left: 0.25rem;
  }
  .mr-1 {
    margin-right: 0.25rem;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .hodo-table figure {
    margin: 0 0 1rem !important;
  }
  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }
  .hodo-table table thead th {
    text-transform: uppercase !important;
  }
  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }
}
</style>
